import React, { useState } from 'react';
import { Navigate } from 'react-router-dom'; // Substituímos Redirect por Navigate
import axios from 'axios';

const ResetPassword = () => {
    const [email, setEmail] = useState('');
    const [sent, setSent] = useState(false);

    const onSubmit = async (e) => {
        e.preventDefault();

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };

        const body = JSON.stringify({ email });

        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/accounts/reset_password/`, body, config);
            setSent(true); // Define o estado para redirecionamento
        } catch (err) {
            console.error('Erro ao enviar o email de redefinição:', err);
        }
    };

    // Redireciona para a página de login após o envio bem-sucedido
    if (sent) {
        return <Navigate to="/login" replace />;
    }

    return (
        <div className="container mt-5">
            <h1>Redefinir Senha</h1>
            <form onSubmit={onSubmit}>
                <div className="form-group">
                    <label className="form-label">Endereço de Email: </label>
                    <input
                        className="form-control"
                        type="email"
                        placeholder="Digite seu email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <button className="btn btn-primary mt-3" type="submit">
                    Enviar Email de Redefinição
                </button>
            </form>
        </div>
    );
};

export default ResetPassword;
