// store.js

import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import rootReducer from './reducers';

// Função para carregar o estado inicial do localStorage
const loadStateFromStorage = () => {
    try {
        const token = localStorage.getItem('token');
        const isAuthenticated = !!token;
        const userRole = localStorage.getItem('userRole') || 'guest'; // Recupera userRole

        return {
            auth: { isAuthenticated, userRole, error: null, successMessage: null, authPending: false }, // Adiciona userRole aqui
        };
    } catch (e) {
        console.error("Erro ao carregar o estado do armazenamento local", e);
        return undefined;
    }
};

const initialState = loadStateFromStorage(); // Carrega o estado inicial

const middleware = [thunk];

const store = createStore(
    rootReducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
