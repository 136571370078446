import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import $ from 'jquery';
import 'jquery-ui/ui/widgets/autocomplete';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/honorarioanestesio.css';
import { Modal, Button } from 'react-bootstrap';




const HonorariosForm = () => {
    const loadSavedState = () => {
        const savedState = localStorage.getItem('honorariosFormState');
        return savedState ? JSON.parse(savedState) : null;
    };
    const loadSelectedTabela = useCallback(() => {
        return localStorage.getItem('tabelaSelecionada') || 'cbhpm18';
    }, []);
    

    const saveProceduresToLocalStorage = useCallback((procedures, tabelaAtual) => {
        const payload = { tabela: tabelaAtual, procedures };
        localStorage.setItem('proceduresPayload', JSON.stringify(payload));
    }, []);

    const loadProceduresFromLocalStorage = useCallback(() => {
        const payload = JSON.parse(localStorage.getItem('proceduresPayload'));
        return payload ? payload : { tabela: loadSelectedTabela(), procedures: [] };
    }, [loadSelectedTabela]);
    
   
    const setupAutocomplete = useCallback((onSelectProcedure) => {
        const $input = $("#procedure-input");
    
        const preventScrollWhileOpen = (e) => {
            const isDropdownOpen = $(".ui-autocomplete:visible").length > 0;
            if (isDropdownOpen && (e.key === "ArrowDown" || e.key === "ArrowUp" || e.type === "wheel")) {
                e.preventDefault();
            }
        };
    
        $input.autocomplete({
            source: async (request, response) => {
                try {
                    const token = localStorage.getItem('token');
                    const selectedTabela = $("#tabela").val(); // Obtém o valor selecionado na combobox
    
                    const res = await $.ajax({
                        url: `${process.env.REACT_APP_API_URL}/api/autocomplete-procedimento`,
                        dataType: "json",
                        data: { term: request.term, tabela: selectedTabela }, // Inclui a tabela na requisição
                        headers: { Authorization: `Bearer ${token}` },
                    });
    
                    const validProcedures = res.map((item) => ({
                        ...item,
                        anestheticPort: item.anestheticPort || '0', // Define '0' se anestheticPort estiver vazio
                    }));
    
                    if (validProcedures.length === 0) {
                        response([{ label: "NENHUM PROCEDIMENTO ENCONTRADO", value: "", isEmpty: true }]);
                    } else {
                        response(
                            validProcedures.filter(item => item.value && item.name).map((item) => ({
                                label: `${item.value} - ${item.name}`,
                                value: item.value,
                                name: item.name,
                                surgeryPort: item.surgeryPort,
                                anestheticPort: item.anestheticPort === '0' ? "Não previsto" : item.anestheticPort,
                                isEmpty: false,
                            }))
                        );
                    }
                } catch (error) {
                    console.error("Erro ao buscar procedimentos:", error);
                    response([{ label: "Erro ao buscar procedimentos", value: "", isEmpty: true }]);
                }
            },
            minLength: 2,
            select: (event, ui) => {
                if (ui.item.value !== "") {
                    const selectedProcedure = {
                        code: ui.item.value,
                        name: ui.item.name,
                        surgeryPort: ui.item.surgeryPort,
                        anestheticPort: ui.item.anestheticPort === "Não previsto" ? "0" : ui.item.anestheticPort
                    };
                    setSelectedProcedure(selectedProcedure); // Atualiza selectedProcedure corretamente
                    $("#procedure-input").val(ui.item.label);
                    $("#procedure-input").autocomplete("close");
                } else {
                    $("#procedure-input").val("");
                }
                return false;
            },
            open: function () {
                const autocompleteWidget = $input.autocomplete("widget");
                autocompleteWidget.css({
                    width: $input.outerWidth() + "px",
                    maxHeight: "200px",
                    overflowY: "auto",
                    position: "absolute",
                });
    
                $(document).on("wheel keydown", preventScrollWhileOpen);
                autocompleteWidget.removeAttr("role aria-live aria-labelledby");
                $input.removeAttr("aria-autocomplete aria-expanded aria-controls aria-activedescendant");
            },
            close: function () {
                $(document).off("wheel keydown", preventScrollWhileOpen);
            },
        });
    
        $input.autocomplete("instance")._renderItem = (ul, item) => {
            if (item.isEmpty) {
                return $("<li class='list-group-item list-group-item-danger text-center'>")
                    .text(item.label)
                    .appendTo(ul);
            } else {
                return $("<li class='list-group-item list-group-item-action'>")
                    .append(
                        `<div>${item.label} <span class='float-right'> | Porte Cirúrgico: ${item.surgeryPort} | Porte Anestésico: ${item.anestheticPort}</span></div>`
                    )
                    .appendTo(ul);
            }
        };
    }, []);
    
    const [procedures, setProcedures] = useState(loadProceduresFromLocalStorage().procedures || []);
    const [tabelasAmb, setTabelasAmb] = useState([]);
    const [tabela, setTabela] = useState(loadSelectedTabela());
    const [acomodacao, setAcomodacao] = useState(loadSavedState()?.acomodacao || 1);
    const [urgencia, setUrgencia] = useState(loadSavedState()?.urgencia || false);
    const [auxiliar, setAuxiliar] = useState(loadSavedState()?.auxiliar || false);
    const [detalhesCalculos, setDetalhesCalculos] = useState([]);
    const [valorPrincipal, setValorPrincipal] = useState(0);
    const [valorAuxiliar, setValorAuxiliar] = useState(0);
    const [categoria] = useState('Original');
    const [highlightMainProcedure, setHighlightMainProcedure] = useState(false);
    const [showCorrectionMessage, setShowCorrectionMessage] = useState(false);
    const [showSaveModal, setShowSaveModal] = useState(false);
    const [listNameInput, setListNameInput] = useState('');
    const [surgeonInput, setSurgeonInput] = useState('');
    const [teamInput, setTeamInput] = useState('');
    const [Specialty, setSpecialty] = useState('Cirurgia Geral'); // Corrigido: variável duplicada
    const [selectedProcedure, setSelectedProcedure] = useState({});


    
    // Salvar o estado de acomodacao, urgencia, e auxiliar
    const saveStateToLocalStorage = useCallback(() => {
        const savedData = {
            tabela,
            acomodacao,
            urgencia,
            auxiliar,
        };
        localStorage.setItem('honorariosFormState', JSON.stringify(savedData));
    }, [tabela, acomodacao, urgencia, auxiliar]);

    useEffect(() => {
        saveStateToLocalStorage(); // Salva no localStorage sempre que o estado muda
    }, [acomodacao, urgencia, auxiliar, tabela, saveStateToLocalStorage]);

    

    const calculateFees = useCallback(async (updatedProcedures = procedures) => {
        const token = localStorage.getItem('token');
        const data = {
            procedimentos: updatedProcedures.map(proc => ({
                name: proc.name,
                codigo: proc.code,
                quantidade: proc.quantity,
                principal: proc.mainProcedure,
                mesmaVia: proc.sameRoute,
                outraVia: proc.differentRoute,
                porteanes: proc.anestheticPort
            })),
            acomodacao,
            urgencia,
            auxiliar,
            tabela,
            categoria,
        };

        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        };

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/calcular-anestesia/`, data, config);
            setDetalhesCalculos(response.data.detalhes || []);
            setValorPrincipal(response.data.valorPrincipal || 0);
            setValorAuxiliar(response.data.valorAuxiliar || 0);
        } catch (error) {
            console.error('Erro ao calcular honorários:', error);
        }
    }, [procedures, acomodacao, urgencia, auxiliar, tabela, categoria]);

    useEffect(() => {
        if (procedures.length > 0) {
            calculateFees(procedures);
            checkForHigherAnestheticPort(procedures);
        }
    }, [tabela, categoria, urgencia, auxiliar, procedures, calculateFees]);


    const updateAndSaveProcedures = useCallback((updatedProcedures) => {
        setProcedures(updatedProcedures);
        saveProceduresToLocalStorage(updatedProcedures, tabela);
    
        // Chamando calculateFees para atualizar os cálculos ou limpar se estiver vazio
        calculateFees(updatedProcedures);
    }, [saveProceduresToLocalStorage, tabela, calculateFees]);
    
    

    const sortProcedures = (procedures) => {
        return [...procedures].sort((a, b) => {
            if (a.mainProcedure) return -1;
            if (b.mainProcedure) return 1;
            if (a.surgeryPort < b.surgeryPort) return -1;
            if (a.surgeryPort > b.surgeryPort) return 1;
            return a.name.localeCompare(b.name);
        });
    };

    const checkForHigherAnestheticPort = (procedures) => {
        const mainProcedure = procedures.find(proc => proc.mainProcedure);
        const hasHigherAnestheticPort = procedures.some(proc => !proc.mainProcedure && parseInt(proc.anestheticPort, 10) > parseInt(mainProcedure.anestheticPort, 10));

        setHighlightMainProcedure(hasHigherAnestheticPort);
        setShowCorrectionMessage(hasHigherAnestheticPort);
    };


    const addProcedure = useCallback(() => {
        const { code, name, surgeryPort, anestheticPort } = selectedProcedure;
        if (!code || !name) return;
    
        // Atualiza o payload no localStorage
        const currentPayload = loadProceduresFromLocalStorage();
        const updatedProcedures = [...currentPayload.procedures];
        const existingProcedure = updatedProcedures.find(proc => proc.code === code);
    
        if (existingProcedure) {
            existingProcedure.quantity += 1;
        } else {
            updatedProcedures.push({
                code,
                name,
                surgeryPort,
                anestheticPort: anestheticPort || '3',
                quantity: 1,
                mainProcedure: updatedProcedures.length === 0,
                sameRoute: true,
                differentRoute: false,
            });
        }
    
        // Salva o payload atualizado no localStorage
        saveProceduresToLocalStorage(updatedProcedures, tabela);
    
        // Carrega a lista de procedimentos a partir do payload atualizado
        const newPayload = loadProceduresFromLocalStorage();
        setProcedures(newPayload.procedures); // Atualiza a lista com base no payload
    
        setSelectedProcedure({});
        $("#procedure-input").val("");
    }, [loadProceduresFromLocalStorage, saveProceduresToLocalStorage, selectedProcedure, tabela]);
    
    


    
    const handleTabelaChange = useCallback(async (selectedTabela) => {
        const token = localStorage.getItem('token');
        const currentPayload = JSON.parse(localStorage.getItem('proceduresPayload')) || { procedures: [] };
        
        setTabela(selectedTabela);  // Atualize o estado `tabela` corretamente
        localStorage.setItem('tabelaSelecionada', selectedTabela);
    
        if (currentPayload.procedures.length > 0) {
            const updatedProcedures = await Promise.all(
                currentPayload.procedures.map(async (proc) => {
                    try {
                        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/atualiza-porte-procedimento`, {
                            params: { codigo: proc.code, tabela: selectedTabela },
                            headers: { Authorization: `Bearer ${token}` },
                        });
                        return {
                            ...proc,
                            surgeryPort: res.data.surgeryPort,
                            anestheticPort: res.data.anestheticPort,
                        };
                    } catch (error) {
                        console.error(`Erro ao atualizar valores para o procedimento ${proc.code}:`, error);
                        return proc;
                    }
                })
            );
    
            // Salva o payload atualizado e configura o estado `procedures`
            localStorage.setItem('proceduresPayload', JSON.stringify({ tabela: selectedTabela, procedures: updatedProcedures }));
            setProcedures(updatedProcedures);
        } else {
            localStorage.setItem('proceduresPayload', JSON.stringify({ tabela: selectedTabela, procedures: [] }));
        }
    }, []);
    
        
    
        const handleQuantityChange = (index, newQuantity) => {
            const updatedProcedures = [...procedures];
            updatedProcedures[index].quantity = parseInt(newQuantity, 10);
            updateAndSaveProcedures(updatedProcedures);
        };
    
        const removeProcedure = (index) => {
            const updatedProcedures = procedures.filter((_, i) => i !== index);
            updateAndSaveProcedures(updatedProcedures);
            calculateFees(updatedProcedures); // Chama calculateFees para atualizar o cálculo
        };
    
        useEffect(() => {
            const savedTabelas = localStorage.getItem('tabelasAmb');
            if (savedTabelas) {
                const formattedTabelas = JSON.parse(savedTabelas).map(tabela => ({
                    value: tabela,
                    label: `CBHPM ${tabela.match(/\d+$/)?.[0]}`
                }));
                setTabelasAmb(formattedTabelas);
            }
        }, []);
    
    
    
    
    


    
    
        const fetchTabelas = useCallback(async () => {
            const savedTabelas = localStorage.getItem('tabelasAmb');
        
            if (savedTabelas) {
                const formattedTabelas = JSON.parse(savedTabelas).map(tabela => {
                    const yearSuffix = tabela.match(/\d+$/)?.[0];
                    const fullYear = `20${yearSuffix}`;
                    return {
                        value: tabela,
                        label: `CBHPM ${fullYear}`
                    };
                });
                setTabelasAmb(formattedTabelas);
                // Defina a tabela apenas se não houver valor no estado `tabela`
                if (!tabela) {
                    setTabela(formattedTabelas[0].value);
                }
            } else {
                try {
                    const token = localStorage.getItem('token');
                    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/buscar-tabelas`, {
                        headers: { Authorization: `Bearer ${token}` },
                    });
                    const tabelas = response.data.tabelas || [];
        
                    const formattedTabelas = tabelas.map(tabela => {
                        const yearSuffix = tabela.match(/\d+$/)?.[0];
                        const fullYear = `20${yearSuffix}`;
                        return {
                            value: tabela,
                            label: `CBHPM ${fullYear}`
                        };
                    });
                    setTabelasAmb(formattedTabelas);
        
                    if (!tabela && formattedTabelas.length > 0) {
                        setTabela(formattedTabelas[0].value);
                        localStorage.setItem('tabelasAmb', JSON.stringify(tabelas));
                    }
                } catch (error) {
                    console.error('Erro ao buscar tabelas:', error);
                    setTabelasAmb([]);
                }
            }
        }, [tabela]);
        

    useEffect(() => {
        fetchTabelas();
        setupAutocomplete(addProcedure);
    }, [fetchTabelas, setupAutocomplete, addProcedure]);




    const clearAllProcedures = () => {
        const updatedProcedures = [];
        setProcedures(updatedProcedures);
        setValorPrincipal(0);
        setValorAuxiliar(0);
        setDetalhesCalculos([]);
        saveProceduresToLocalStorage(updatedProcedures, tabela); // Salva o payload atualizado
    }
    

    const clearProcedure = () => {
        setSelectedProcedure({});
        saveProceduresToLocalStorage(procedures, tabela); // Atualiza o localStorage sem mudanças nos procedimentos
        $("#procedure-input").val("");
    };
    

    const handleSameRouteChange = (index) => {
        const updatedProcedures = [...procedures];
        updatedProcedures[index].sameRoute = true;
        updatedProcedures[index].differentRoute = false;
        setProcedures(sortProcedures(updatedProcedures));
        saveProceduresToLocalStorage(updatedProcedures, tabela); // Salva o payload atualizado
    };

    const handleDifferentRouteChange = (index) => {
        const updatedProcedures = [...procedures];
        updatedProcedures[index].sameRoute = false;
        updatedProcedures[index].differentRoute = true;
        setProcedures(sortProcedures(updatedProcedures));
        saveProceduresToLocalStorage(updatedProcedures, tabela); // Salva o payload atualizado
    };

    const handleMainProcedureChange = (index) => {
        const updatedProcedures = [...procedures];
        updatedProcedures.forEach((p, i) => {
            if (i === index) {
                p.mainProcedure = true;
                p.sameRoute = true;
                p.differentRoute = false;
            } else {
                p.mainProcedure = false;
            }
        });
        setProcedures(sortProcedures(updatedProcedures));
        saveProceduresToLocalStorage(updatedProcedures, tabela); // Salva o payload atualizado
    };

    const correctMainProcedure = () => {
        const updatedProcedures = [...procedures];
        const highestAnestheticPort = updatedProcedures.reduce((highest, proc) => {
            return parseInt(proc.anestheticPort, 10) > parseInt(highest.anestheticPort, 10) ? proc : highest;
        }, updatedProcedures[0]);

        handleMainProcedureChange(procedures.indexOf(highestAnestheticPort));
    };

    // Modificação no botão de copiar
    const copyToClipboard = () => {
        const detalhesText = detalhesCalculos.map(line => 
            line === "------" 
            ? "------"
            : line
                .replace(/\| +\|/g, ' ')
                .replace(/ R\$/, " R$ ")
                .replace(/\(unitário\)/g, "(unitário)")
                .replace(/\(multiplicado\)/g, "(multiplicado)")
        ).join('\n');
        
        navigator.clipboard.writeText(detalhesText);
    };
    

    const saveProcedureList = async () => {
        const listData = {
            listName: listNameInput,
            surgeon: surgeonInput,
            team: teamInput,
            Specialty: Specialty,
            tabela: tabela,
            sharedWith: [], // Popule com IDs de usuários para compartilhar a lista, se necessário
            procedures: procedures.map(proc => ({
                code: proc.code,
                name: proc.name,
                surgeryPort: proc.surgeryPort,
                anestheticPort: proc.anestheticPort,
                quantity: proc.quantity,
                mainProcedure: proc.mainProcedure,
                sameRoute: proc.sameRoute,
                differentRoute: proc.differentRoute,
                valorUnitario: valorPrincipal, // exemplo de valor, ajuste conforme necessário
            }))
        };
    
        try {
            const token = localStorage.getItem('token');
            await axios.post(`${process.env.REACT_APP_API_URL}/api/procedure-lists`, listData, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setShowSaveModal(false);
        } catch (error) {
            console.error("Erro ao salvar a lista:", error);
        }
    };
    // Função para exibir os detalhes dos cálculos
    const renderDetalhesCalculos = () => (
        <table className="table">
            <thead>
                <tr>
                    <th>Quantidade</th>
                    <th>Código</th>
                    <th>Procedimento</th>
                    <th>Porte Anestésico</th>
                    <th>Característica</th>
                    <th>Valor Unitário</th>
                    <th>Valor Multiplicado</th>
                </tr>
            </thead>
            <tbody>
                {detalhesCalculos.map((detalhe, index) => (
                    detalhe === "------" ? (
                        <tr key={index} className="table-separator">
                            <td colSpan="7"><hr /></td>
                        </tr>
                    ) : (
                        <tr key={index}>
                            {detalhe.split("|").map((col, i) => (
                                <td key={i}>{col.trim().replace("(unitário)", "").replace("(multiplicado)", "")}</td>
                            ))}
                        </tr>
                    )
                ))}
            </tbody>
        </table>
    );

    return (
<div className="container mt-5">
    <h1>Calcular Honorários de Anestesia</h1>
    <form id="form-codigos">

    <div className="form-row mt-3">
    <label htmlFor="tabela" className="form-label">Tabela:</label>
    <select
        id="tabela"
        className="form-select"
        value={tabela}
        onChange={(e) => handleTabelaChange(e.target.value)} // chama `handleTabelaChange` com o valor selecionado
    >
        {tabelasAmb.length > 0 ? (
            tabelasAmb.map(({ value, label }) => (
                <option key={value} value={value}>
                    {label}
                </option>
            ))
        ) : (
            <option disabled>Carregando tabelas...</option>
        )}
    </select>
</div>


        <div className="form-row position-relative">
            <label htmlFor="procedure-input" className="form-label">
                Código ou Nome do Procedimento:
            </label>
            <input type="text" id="procedure-input" className="form-control" placeholder="Digite o procedimento" />
            
            {/* Dropdown de Autocomplete */}
            <ul id="autocomplete-dropdown" className="list-unstyled position-absolute w-100"></ul>
            
            <div className="mt-2">
                <button type="button" className="btn btn-success me-2" onClick={addProcedure}>
                    Adicionar
                </button>
                <button type="button" className="btn btn-secondary" onClick={clearProcedure}>
                    Limpar
                </button>
            </div>
        </div>
                {/* Botão de Salvar Lista */}
                <button type="button" className="btn btn-primary mt-3" onClick={() => setShowSaveModal(true)}>
                    Salvar Lista
                </button>

                {/* Modal de Salvamento */}
                <Modal show={showSaveModal} onHide={() => setShowSaveModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Salvar Lista de Procedimentos</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <input
                            type="text"
                            placeholder="Nome da Lista"
                            className="form-control mb-3"
                            value={listNameInput}
                            onChange={(e) => setListNameInput(e.target.value)}
                        />
                        <input
                            type="text"
                            placeholder="Cirurgião"
                            className="form-control mb-3"
                            value={surgeonInput}
                            onChange={(e) => setSurgeonInput(e.target.value)}
                        />
                        <input
                            type="text"
                            placeholder="Equipe"
                            className="form-control mb-3"
                            value={teamInput}
                            onChange={(e) => setTeamInput(e.target.value)}
                        />
                        <input
                            type="text"
                            placeholder="Área (Cirurgia Geral, Neurocirurgia...)"
                            className="form-control"
                            value={Specialty}
                            onChange={(e) => setSpecialty(e.target.value)}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowSaveModal(false)}>
                            Cancelar
                        </Button>
                        <Button variant="primary" onClick={saveProcedureList}>
                            Salvar
                        </Button>
                    </Modal.Footer>
                </Modal>

                <div className="form-row mt-3">
                    <label htmlFor="acomodacao" className="form-label">Acomodação:</label>
                    <select id="acomodacao" className="form-select" value={acomodacao} onChange={(e) => setAcomodacao(parseInt(e.target.value))}>
                        <option value="1">Enfermaria</option>
                        <option value="2">Quarto/Apartamento</option>
                    </select>
                </div>

                <div className="form-check mt-3">
                    <input type="checkbox" className="form-check-input" id="urgencia" checked={urgencia} onChange={(e) => setUrgencia(e.target.checked)} />
                    <label htmlFor="urgencia" className="form-check-label">Horário de Urgência</label>
                </div>

                <div className="form-check mt-3">
                    <input type="checkbox" className="form-check-input" id="auxiliar" checked={auxiliar} onChange={(e) => setAuxiliar(e.target.checked)} />
                    <label htmlFor="auxiliar" className="form-check-label">Anestesista Auxiliar</label>
                </div>


                <div className="mt-4">
                    <h3>Procedimentos Adicionados</h3>
                    <div className="table-zoom-wrapper">
                        <table className="table table-bordered table-striped procedures-list">
                            <thead className="table-dark">
                                <tr>
                                    <th>Código</th>
                                    <th>Nome</th>
                                    <th>Porte Cirúrgico</th>
                                    <th>Porte Anestésico</th>
                                    <th>Quantidade</th>
                                    <th>Principal</th>
                                    <th>Mesma Via</th>
                                    <th>Outra Via</th>
                                    <th>Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                {procedures.map((proc, index) => (
                                    <tr key={index} style={{ backgroundColor: proc.mainProcedure && highlightMainProcedure ? 'yellow' : 'transparent' }}>
                                        <td>{proc.code}</td>
                                        <td>{proc.name}</td>
                                        <td>{proc.surgeryPort}</td>
                                        <td>{proc.anestheticPort}</td>
                                        <td>
                                            <input
                                                type="number"
                                                className="form-control"
                                                value={proc.quantity}
                                                onChange={(e) => handleQuantityChange(index, e.target.value)}
                                                min="1"
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="radio"
                                                checked={proc.mainProcedure}
                                                onChange={() => handleMainProcedureChange(index)}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="checkbox"
                                                checked={proc.sameRoute}
                                                disabled={proc.mainProcedure}
                                                onChange={() => handleSameRouteChange(index)}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="checkbox"
                                                checked={proc.differentRoute}
                                                disabled={proc.mainProcedure}
                                                onChange={() => handleDifferentRouteChange(index)}
                                            />
                                        </td>
                                        <td>
                                            <button type="button" className="btn btn-danger btn-sm" onClick={() => removeProcedure(index)}>Remover</button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    {showCorrectionMessage && (
                        <div className="alert alert-warning mt-3">
                            Procedimento principal possui porte anestésico inferior. <span onClick={correctMainProcedure} style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}>Corrigir</span>
                        </div>
                    )}
                </div>


                <div className="mt-4">
                    <button type="button" className="btn btn-warning" onClick={clearAllProcedures}>Limpar Tabela</button>
                </div>

                <div className="mt-4">
                    <h3>Valores Calculados</h3>
                    <p>
                        Valor Anestesista Principal: <span id="valor-principal">R$ {valorPrincipal.toFixed(2)}</span>{' '}
                        <button type="button" className="btn btn-link" onClick={() => copyToClipboard("Anestesista principal", valorPrincipal)}>
                            Copiar Valor
                        </button>
                    </p>
                    {auxiliar && (
                        <p>
                            Valor Anestesista Auxiliar: <span id="valor-auxiliar">R$ {valorAuxiliar.toFixed(2)}</span>{' '}
                            <button type="button" className="btn btn-link" onClick={() => copyToClipboard("Anestesista auxiliar", valorAuxiliar)}>
                                Copiar Valor
                            </button>
                        </p>
                    )}
                </div>

                <div className="mt-4">
    <h3>Cálculos Detalhados</h3>
    {renderDetalhesCalculos()}
    <button type="button" className="btn btn-link mt-2" onClick={copyToClipboard}>
        Copiar Cálculos
    </button>
</div>
            </form>
        </div>
    );
};

export default HonorariosForm;
