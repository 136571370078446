import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { get_menu, reset_menu } from '../actions/menu';
import { logout } from '../actions/auth';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/menu.css';

const Sidebar = ({ menu = [], get_menu, reset_menu, logout, isOpen, toggleSidebar, userRole }) => {
    const [loading, setLoading] = useState(true);
    const [showLoadingMessage, setShowLoadingMessage] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchMenu = async () => {
            setLoading(true);
            try {
                if (userRole !== 'guest') {
                    await get_menu(userRole);
                }
            } catch (error) {
                console.error('Erro ao carregar o menu:', error);
            } finally {
                setLoading(false);
            }
        };

        if (userRole !== 'guest') {
            fetchMenu();
        } else {
            setLoading(false); // Não carrega do backend se for guest
        }
    }, [get_menu, userRole]);

    useEffect(() => {
        // Inicia o delay de 2 segundos antes de mostrar a mensagem de carregamento
        const timer = setTimeout(() => setShowLoadingMessage(true), 2000);
        
        // Limpa o timer caso o menu seja carregado antes dos 2 segundos
        return () => clearTimeout(timer);
    }, []);

    const handleLogout = () => {
        logout();
        reset_menu();
        navigate('/login');
        toggleSidebar();
    };

    return (
        <div className={`menu sidebar ${isOpen ? 'active' : ''}`}>
            <button
                className="menu-toggle"
                type="button"
                onClick={toggleSidebar}
                aria-expanded={isOpen}
                aria-label="Toggle navigation"
                style={{
                    position: 'fixed',
                    top: '20px',
                    left: '15px',
                    zIndex: 1100
                }}
            >
                ☰
            </button>

            <div className={`sidebar-menu ${isOpen || window.innerWidth >= 1060 ? 'show' : ''}`}>
                <ul className="nav flex-column">
                    {loading && showLoadingMessage ? (
                        <li className="nav-item">
                            <span className="nav-link">Carregando...</span>
                        </li>
                    ) : menu.length > 0 ? (
                        menu.map((option, index) => (
                            <li key={index} className="nav-item">
                                {option.action === 'logout' ? (
                                    <a className="nav-link" href="#!" onClick={handleLogout}>
                                        {option.label}
                                    </a>
                                ) : (
                                    <NavLink
                                        to={option.url}
                                        className={({ isActive }) =>
                                            isActive ? 'nav-link active' : 'nav-link'
                                        }
                                        onClick={toggleSidebar}
                                    >
                                        {option.label}
                                    </NavLink>
                                )}
                            </li>
                        ))
                    ) : showLoadingMessage ? (
                        <li className="nav-item">
                            <span className="nav-link">Carregando...</span>
                        </li>
                    ) : null}
                </ul>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    menu: state.menu.menu,
    userRole: state.auth.userRole,
});

export default connect(mapStateToProps, { get_menu, reset_menu, logout })(Sidebar);
