import React, { useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { checkAuthenticated } from '../actions/auth'; // Ajuste o caminho se necessário

const PrivateRoute = () => {
    const dispatch = useDispatch();
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token && !isAuthenticated) {
            dispatch(checkAuthenticated()); // Dispara a ação corretamente
        }
    }, [dispatch, isAuthenticated]);

    if (!isAuthenticated) {
        return <Navigate to="/login" replace />;
    }

    const userRole = localStorage.getItem('userRole');
    if (userRole === 'admin') {
        return <Navigate to="/admin" replace />;
    }

    return <Outlet />;
};

export default PrivateRoute;
