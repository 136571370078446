import axios from 'axios';

// Define a URL base usando a variável de ambiente
const API_URL = process.env.REACT_APP_API_URL;

// Função para salvar um template, utilizando a URL definida no .env
export const saveTemplate = async (formData) => {
    try {
        const response = await axios.post(`${API_URL}/api/TemplateDocumentos/save`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
            withCredentials: true, // Habilita o envio de cookies para a API
        });
        return response.data;
    } catch (error) {
        console.error('Erro ao salvar template:', error);
        throw error; // Propaga o erro para ser tratado pela chamada da função
    }
};
