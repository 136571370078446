import React, { useState } from 'react';
import HonorariosForm from './HonorariosForm';
import HonorariosArquivo from './HonorariosArquivo';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/honorarioanestesio.css';

const HonorariosContainer = () => {
    const [activeTab, setActiveTab] = useState('novo');
    const [procedures, setProcedures] = useState([]); // Estado para armazenar procedimentos da aba "Novo"

    return (
        <div className="container mt-5">
            {/* Abas */}
            <div className="nav nav-tabs">
                <button
                    className={`nav-link ${activeTab === 'novo' ? 'active' : ''}`}
                    onClick={() => setActiveTab('novo')}
                >
                    Novo
                </button>
                <button
                    className={`nav-link ${activeTab === 'arquivo' ? 'active' : ''}`}
                    onClick={() => setActiveTab('arquivo')}
                >
                    Arquivo
                </button>
            </div>

            {/* Conteúdo das abas */}
            <div className="tab-content mt-3">
                {activeTab === 'novo' && (
                    <HonorariosForm 
                        procedures={procedures} 
                        setProcedures={setProcedures} 
                    />
                )}
                {activeTab === 'arquivo' && <HonorariosArquivo />}
            </div>
        </div>
    );
};

export default HonorariosContainer;
