import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { get_menu } from '../actions/menu';

const Dashboard = ({ get_menu }) => {
    const [firstName, setFirstName] = useState(localStorage.getItem('primeiro_nome') || '');
    const [lastAccess, setLastAccess] = useState(localStorage.getItem('last_login') || '');

    const fetchUserData = useCallback(async () => {
        const token = localStorage.getItem('token'); // Obtém o token JWT do localStorage

        if (!token) {
            console.error("Token não encontrado no localStorage.");
            return;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/accounts/user`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!response.ok) {
                throw new Error(`Erro na requisição: ${response.status}`);
            }

            const resData = await response.json();
            const { primeiro_nome, last_login } = resData;

            // Conversão para o horário de Brasília (UTC-3)
            const lastLoginDate = new Date(last_login);
            lastLoginDate.setHours(lastLoginDate.getHours() - 3);
            const formattedLastLogin = lastLoginDate.toLocaleString('pt-BR', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit'
            });

            // Armazena as novas informações no localStorage
            localStorage.setItem('primeiro_nome', primeiro_nome);
            localStorage.setItem('last_login', formattedLastLogin);

            // Atualiza o estado do componente
            setFirstName(primeiro_nome);
            setLastAccess(formattedLastLogin);
        } catch (error) {
            console.error("Erro ao obter os dados do usuário:", error);
        }
    }, []);

    useEffect(() => {
        fetchUserData();
        get_menu();
    }, [fetchUserData, get_menu]);

    return (
        <div className='container'>
            <h1 className='mt-3'>
                {lastAccess === 'Primeiro login'
                    ? `Bem-vindo ao seu primeiro acesso, ${firstName || 'Usuário'}!`
                    : `Bem-vindo, ${firstName || 'Usuário'}. Seu último login foi em ${lastAccess || 'não disponível'} (horário de Brasília).`}
            </h1>
            <p className='mt-3'>Esta página está atualmente em branco. Mais funcionalidades serão adicionadas no futuro.</p>
        </div>
    );
};

const mapDispatchToProps = {
    get_menu
};

export default connect(null, mapDispatchToProps)(Dashboard);
