import React, { useState, useEffect, useCallback } from 'react';
import { Navigate , Link, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { register } from '../actions/auth';
import { Modal } from 'react-bootstrap';  // Usando modal do Bootstrap

const Register = ({ register, isAuthenticated }) => {
    const [formData, setFormData] = useState({
        Username: '',
        PrimeiroNome: '',
        Sobrenome: '',
        Email: '',
        CPF: '',
        DataNascimento: '',
        genero: '',
        password: '',
        re_password: ''
    });

    const [errors, setErrors] = useState({});
    const [passwordStrength, setPasswordStrength] = useState({ color: 'text-danger', message: '' });
    const [showModal, setShowModal] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [countdown, setCountdown] = useState(3); // Estado para a contagem regressiva
    const history = useNavigate(); // Usado para redirecionamento
    const { Username, PrimeiroNome, Sobrenome, Email, CPF, DataNascimento, genero, password, re_password } = formData;

    const onChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setErrors({}); // Limpa os erros ao alterar o formulário
    };

    const formatCPF = value => {
        const cleaned = ('' + value).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})$/);
        if (match) {
            return `${match[1] ? match[1] : ''}${match[2] ? '.' + match[2] : ''}${match[3] ? '.' + match[3] : ''}${match[4] ? '-' + match[4] : ''}`;
        }
        return value;
    };

    const formatDate = value => {
        const cleaned = ('' + value).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{0,2})(\d{0,2})(\d{0,4})$/);
        if (match) {
            return `${match[1] ? match[1] : ''}${match[2] ? '/' + match[2] : ''}${match[3] ? '/' + match[3] : ''}`;
        }
        return value;
    };

    const handleCPFChange = e => {
        setFormData({ ...formData, CPF: formatCPF(e.target.value) });
    };

    const handleDateChange = e => {
        setFormData({ ...formData, DataNascimento: formatDate(e.target.value) });
    };

    const validatePassword = useCallback(() => {
        let message = 'A senha deve conter maiúsculas, minúsculas, números e caracteres especiais.';
        let isValid = /[A-Z]/.test(password) && /[a-z]/.test(password) && /\d/.test(password) && /[!@#$%^&*(),.?":{}|<>]/.test(password);

        if (!isValid) {
            setPasswordStrength({ color: 'text-danger', message });
            setErrors(prev => ({ ...prev, passwordWeak: message }));
        } else {
            setPasswordStrength({ color: 'text-success', message: 'Senha forte!' });
            setErrors(prev => ({ ...prev, passwordWeak: null }));
        }

        if (password !== re_password) {
            setErrors(prev => ({ ...prev, passwordMismatch: 'As senhas não coincidem' }));
        } else {
            setErrors(prev => ({ ...prev, passwordMismatch: null }));
        }
    }, [password, re_password]);

    const convertDateToISO = (date) => {
        const [day, month, year] = date.split('/');
        return `${year}-${month}-${day}`;  // Converte para o formato YYYY-MM-DD
    };

    const onSubmit = e => {
        e.preventDefault();

        const cleanedCPF = CPF.replace(/\D/g, '');  // Remove non-digit characters
        const isoDate = convertDateToISO(DataNascimento);  // Convert date format to ISO

        if (password === re_password) {
            register(Username, PrimeiroNome, Sobrenome, Email, cleanedCPF, isoDate, genero, password, re_password)
                .then(res => {
                    if (res.success) {
                        setSuccessMessage(res.message);
                        setShowModal(true);  // Exibe o modal de sucesso
                        // Inicia a contagem regressiva para o redirecionamento
                        const interval = setInterval(() => {
                            setCountdown(prev => prev - 1);
                        }, 1000);

                        setTimeout(() => {
                            clearInterval(interval);
                            history('/login'); // Redireciona para a página de login após 3 segundos
                        }, 3000);
                    } else {
                        // Tratamento de erros individuais vindos do backend
                        if (res.errors && Array.isArray(res.errors)) {
                            const backendErrors = res.errors.reduce((acc, error) => {
                                if (error.includes('CPF')) acc.CPF = error;
                                if (error.includes('Username')) acc.Username = error;
                                if (error.includes('Email')) acc.Email = error;
                                return acc;
                            }, {});
                            setErrors(backendErrors);
                        } else {
                            setErrors({ message: res.message });
                        }
                    }
                })
                .catch(err => {
                    setErrors({ serverError: 'Ocorreu um erro no servidor. Tente novamente mais tarde.' });
                });
        } else {
            setErrors({ passwordMismatch: 'As senhas não coincidem.' });
        }
    };

    useEffect(() => {
        validatePassword();  // Valida a senha quando os campos mudarem
    }, [password, re_password, validatePassword]);

    if (isAuthenticated) return <Navigate  to='/dashboard' />;

    return (
        <div className='container mt-5'>
            <h1>Criar uma Conta</h1>
            <p>Preencha o formulário abaixo para criar uma nova conta.</p>
            <form onSubmit={e => onSubmit(e)}>
                <div className='form-group'>
                    <label className='form-label'>Nome de usuário: </label>
                    <input
                        className={`form-control ${errors.Username ? 'is-invalid' : ''}`}
                        type='text'
                        placeholder='Nome de usuário*'
                        name='Username'
                        onChange={e => onChange(e)}
                        value={Username}
                        required
                    />
                    {errors.Username && <small className="text-danger">{errors.Username}</small>}
                </div>
                <div className='form-group'>
                    <label className='form-label mt-3'>Primeiro Nome: </label>
                    <input
                        className='form-control'
                        type='text'
                        placeholder='Primeiro Nome*'
                        name='PrimeiroNome'  
                        onChange={e => onChange(e)}
                        value={PrimeiroNome}
                        required
                    />
                </div>
                <div className='form-group'>
                    <label className='form-label mt-3'>Sobrenome: </label>
                    <input
                        className='form-control'
                        type='text'
                        placeholder='Sobrenome*'
                        name='Sobrenome'
                        onChange={e => onChange(e)}
                        value={Sobrenome}
                        required
                    />
                </div>
                <div className='form-group'>
                    <label className='form-label mt-3'>Email: </label>
                    <input
                        className={`form-control ${errors.Email ? 'is-invalid' : ''}`}
                        type='Email'
                        placeholder='Email*'
                        name='Email'
                        onChange={e => onChange(e)}
                        value={Email}
                        required
                    />
                    {errors.Email && <small className="text-danger">{errors.Email}</small>}
                </div>
                <div className='form-group'>
                    <label className='form-label mt-3'>CPF: </label>
                    <input
                        className={`form-control ${errors.CPF ? 'is-invalid' : ''}`}
                        type='text'
                        placeholder='CPF*'
                        name='CPF'
                        maxLength='14'
                        onChange={handleCPFChange}
                        value={CPF}
                        required
                    />
                    {errors.CPF && <small className="text-danger">{errors.CPF}</small>}
                </div>
                <div className='form-group'>
                    <label className='form-label mt-3'>Data de Nascimento: </label>
                    <input
                        className='form-control'
                        type='text'
                        placeholder='DD/MM/AAAA'
                        name='DataNascimento'  
                        maxLength='10'
                        onChange={handleDateChange}
                        value={DataNascimento}
                        required
                    />
                </div>
                <div className='form-group'>
                    <label className='form-label mt-3'>Gênero:</label>
                    <select
                        className='form-control'
                        name='genero'
                        onChange={onChange}
                        value={genero}
                        required
                    >
                        <option value="">Selecione</option>
                        <option value="Masculino">Masculino</option>
                        <option value="Feminino">Feminino</option>
                        <option value="Outro">Outro</option>
                    </select>
                </div>
                <div className='form-group'>
                <label className='form-label mt-3'>Senha: </label>
                    <input
                        className={`form-control ${errors.passwordWeak ? 'is-invalid' : ''}`}
                        type='password'
                        placeholder='Senha*'
                        name='password'
                        onChange={e => onChange(e)}
                        value={password}
                        minLength='6'
                        required
                    />
                    <small className={passwordStrength.color}>{passwordStrength.message}</small>
                    {errors.passwordWeak && <small className="text-danger">{errors.passwordWeak}</small>}
                </div>
                <div className='form-group'>
                    <label className='form-label mt-3'>Confirme a Senha: </label>
                    <input
                        className={`form-control ${errors.passwordMismatch ? 'is-invalid' : ''}`}
                        type='password'
                        placeholder='Confirme a Senha*'
                        name='re_password'
                        onBlur={validatePassword}
                        onChange={e => onChange(e)}
                        value={re_password}
                        minLength='6'
                        required
                    />
                    {errors.passwordMismatch && <small className="text-danger">{errors.passwordMismatch}</small>}
                </div>
                <button className='btn btn-primary mt-3' type='submit'>Registrar</button>
            </form>

            {/* Exibe erros gerais no final do formulário */}
            {errors.message && <div className="alert alert-danger mt-3">{errors.message}</div>}

            <p className='mt-3'>
                Já tem uma conta? <Link to='/login'>Entrar</Link>
            </p>

            {/* Modal de sucesso */}
            <Modal show={showModal} onHide={() => setShowModal(false)} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Registro Bem Sucedido</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{successMessage}</p>
                    <p>Redirecionando para o login em {countdown} segundos...</p>
                </Modal.Body>
            </Modal>
        </div>
    );
};

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { register })(Register);
