import { combineReducers } from 'redux';
import auth from './auth';
import 'bootstrap/dist/css/bootstrap.min.css';
import menuReducer from './menuReducer';  // Importa o reducer do menu

// Combina todos os reducers em um único objeto de estado global
export default combineReducers({
    auth,
    menu: menuReducer  // Adiciona o reducer do menu
});
