import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Modal, Button, Form, Table } from 'react-bootstrap';
import '../assets/css/honorarioanestesio.css';

const HonorariosArquivo = () => {
    const [procedureLists, setProcedureLists] = useState(JSON.parse(localStorage.getItem('procedureLists')) || []);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedList, setSelectedList] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedListIds, setSelectedListIds] = useState([]);
    const [showShared, setShowShared] = useState(false);
    const [sharedWith, setSharedWith] = useState('');
    const [acomodacao, setAcomodacao] = useState('Enfermaria');
    const [auxiliar, setAuxiliar] = useState(false);
    const [urgencia, setUrgencia] = useState(false);
    const [sortConfig, setSortConfig] = useState(null);

    const fetchProcedureLists = useCallback(async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/procedure-lists`, {
                headers: { Authorization: `Bearer ${token}` },
                params: { sharedWith: showShared ? localStorage.getItem('user_id') : null }
            });
            const lists = Array.isArray(response.data) ? response.data : [];
            setProcedureLists(lists);
            localStorage.setItem('procedureLists', JSON.stringify(lists));
        } catch (error) {
            console.error("Erro ao buscar listas de procedimentos:", error);
            setProcedureLists([]);
        }
    }, [showShared]);

    const toggleShowShared = () => {
        setShowShared(!showShared);
        fetchProcedureLists();
    };

    const handleEditList = (list) => {
        localStorage.setItem('proceduresPayload', JSON.stringify({ tabela: list.tabela, procedures: list.procedures }));
        window.location.href = "/honorarios-form"; // Redirect to edit form
    };

    const toggleSelection = (listId) => {
        setSelectedListIds(prevIds =>
            prevIds.includes(listId)
                ? prevIds.filter(id => id !== listId)
                : [...prevIds, listId]
        );
    };

    const deleteSelectedLists = async () => {
        const token = localStorage.getItem('token');
        try {
            await Promise.all(
                selectedListIds.map(id =>
                    axios.delete(`${process.env.REACT_APP_API_URL}/api/procedure-lists/${id}`, {
                        headers: { Authorization: `Bearer ${token}` }
                    })
                )
            );
            setProcedureLists(prevLists => prevLists.filter(list => !selectedListIds.includes(list.id)));
            setSelectedListIds([]);
            setShowDeleteModal(false);
            localStorage.removeItem('procedureLists');
        } catch (error) {
            console.error("Erro ao deletar listas:", error);
        }
    };

    const openDetailsModal = (list) => {
        setSelectedList(list);
    };

    const deleteSelectedList = async () => {
        const token = localStorage.getItem('token');
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/api/procedure-lists/${selectedList.id}`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setProcedureLists(prevLists => prevLists.filter(list => list.id !== selectedList.id));
            setSelectedList(null);
            localStorage.removeItem('procedureLists');
        } catch (error) {
            console.error("Erro ao deletar lista:", error);
        }
    };

    const renderDetalhesCalculos = () => (
        <table className="table">
            <thead>
                <tr>
                    <th>Quantidade</th>
                    <th>Código</th>
                    <th>Procedimento</th>
                    <th>Porte Anestésico</th>
                    <th>Característica</th>
                    <th>Valor Unitário</th>
                    <th>Valor Multiplicado</th>
                </tr>
            </thead>
            <tbody>
                {honorarios.detalhes.map((detalhe, index) => (
                    detalhe === "------" ? (
                        <tr key={index} className="table-separator">
                            <td colSpan="7"><hr /></td>
                        </tr>
                    ) : (
                        <tr key={index}>
                            {detalhe.split("|").map((col, i) => (
                                <td key={i}>{col.trim().replace(/\(unitário\)|\(multiplicado\)|Porte Anestésico /g, '')}</td>
                            ))}
                        </tr>
                    )
                ))}
            </tbody>
        </table>
    );

    const copyToClipboard = () => {
        const detalhesText = honorarios.detalhes.map(line =>
            line === "------"
                ? "------"
                : line
        ).join('\n');

        navigator.clipboard.writeText(detalhesText);
    };

    const handleAddSharedUser = () => {
        setSelectedList(prevList => ({
            ...prevList,
            sharedWith: [...(prevList.sharedWith || []), sharedWith]
        }));
        setSharedWith('');
    };

    const calculateHonorarios = (list) => {
        let totalPrincipal = 0;
        let totalAuxiliar = 0;
        const detalhes = [];
        let aplicou70PorCento = false;

        const procedimentosOutraVia = list.procedures
            .filter(proc => proc.differentRoute)
            .sort((a, b) => parseInt(b.anestheticPort) - parseInt(a.anestheticPort));

        list.procedures.forEach(proc => {
            const valorUnitario = parseFloat(proc.valorUnitario || 0);
            let multiplicado = valorUnitario * proc.quantity;
            let detalhe = `${proc.quantity}x | ${proc.code} | ${proc.name} | Porte Anestésico ${proc.anestheticPort} | `;

            if (proc.mainProcedure) {
                totalPrincipal += multiplicado;
                detalhe += `Principal (100%) | R$ ${valorUnitario.toFixed(2)} | R$ ${multiplicado.toFixed(2)}`;
            } else if (proc.differentRoute) {
                const fator = !aplicou70PorCento && procedimentosOutraVia.some(p => p.code === proc.code) ? 0.7 : 0.5;
                multiplicado *= fator;
                aplicou70PorCento = fator === 0.7;
                detalhe += `Secundário (${fator * 100}% - Outra Via) | R$ ${valorUnitario.toFixed(2)} | R$ ${multiplicado.toFixed(2)}`;
                totalPrincipal += multiplicado;
            } else {
                multiplicado *= 0.5;
                detalhe += `Secundário (50%) | R$ ${valorUnitario.toFixed(2)} | R$ ${multiplicado.toFixed(2)}`;
                totalPrincipal += multiplicado;
            }

            detalhes.push(detalhe);
        });

        // Modificadores adicionais
        totalPrincipal *= acomodacao === 'Quarto/Apartamento' ? 2 : 1;
        detalhes.push("------");
        detalhes.push(`Total após acomodação (x${acomodacao === 'Quarto/Apartamento' ? 2 : 1}): R$ ${totalPrincipal.toFixed(2)}`);

        if (urgencia) {
            const valorUrgencia = totalPrincipal * 0.3;
            totalPrincipal *= 1.3;
            detalhes.push("------");
            detalhes.push(`Adicionado 30% por urgência: R$ ${valorUrgencia.toFixed(2)}`);
        }

        if (auxiliar) {
            totalAuxiliar = totalPrincipal * 0.3;
            detalhes.push("------");
            detalhes.push(`Valor Anestesista Principal: R$ ${totalPrincipal.toFixed(2)}`);
            detalhes.push(`Valor Anestesista Auxiliar: R$ ${totalAuxiliar.toFixed(2)}`);
            detalhes.push(`Valor total: R$ ${(totalPrincipal + totalAuxiliar).toFixed(2)}`);
        } else {
            detalhes.push("------");
            detalhes.push(`Valor total: R$ ${totalPrincipal.toFixed(2)}`);
        }

        return { total: auxiliar ? totalPrincipal + totalAuxiliar : totalPrincipal, detalhes };
    };

    const honorarios = selectedList ? calculateHonorarios(selectedList) : { total: 0, detalhes: [] };

    useEffect(() => {
        if (!localStorage.getItem('procedureLists')) {
            fetchProcedureLists();
        }
    }, [fetchProcedureLists]);

    const sortTable = (key) => {
        let direction = 'ascending';
        if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const sortedProcedureLists = [...procedureLists].sort((a, b) => {
        if (sortConfig !== null) {
            if (a[sortConfig.key] < b[sortConfig.key]) {
                return sortConfig.direction === 'ascending' ? -1 : 1;
            }
            if (a[sortConfig.key] > b[sortConfig.key]) {
                return sortConfig.direction === 'ascending' ? 1 : -1;
            }
        }
        return 0;
    });

    const formatTableName = (tableName) => {
        if (!tableName) return 'Desconhecido';
        const yearSuffix = tableName.match(/\d{2}$/)?.[0];
        return yearSuffix ? `CBHPM 20${yearSuffix}` : tableName;
    };

    return (
        <div className="mt-3">
            <input
                type="text"
                placeholder="Buscar lista"
                className="form-control mb-3"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Form.Check
                type="checkbox"
                label="Mostrar listas compartilhadas comigo"
                checked={showShared}
                onChange={toggleShowShared}
            />
            <Table bordered hover>
                <thead className="table-dark">
                    <tr>
                        <th><Form.Check type="checkbox" onChange={(e) => {
                            if (e.target.checked) {
                                setSelectedListIds(sortedProcedureLists.map(list => list.id));
                            } else {
                                setSelectedListIds([]);
                            }
                        }} /></th>
                        <th onClick={() => sortTable('listName')}>Nome da lista de procedimentos</th>
                        <th onClick={() => sortTable('surgeon')}>Cirurgião</th>
                        <th onClick={() => sortTable('team')}>Equipe</th>
                        <th onClick={() => sortTable('specialty')}>Especialidade</th>
                    </tr>
                </thead>
                <tbody>
                    {sortedProcedureLists.filter(list => list.listName.toLowerCase().includes(searchTerm.toLowerCase())).map((list) => (
                        <tr key={list.id}>
                            <td>
                                <Form.Check
                                    type="checkbox"
                                    checked={selectedListIds.includes(list.id)}
                                    onChange={() => toggleSelection(list.id)}
                                />
                            </td>
                            <td onClick={() => openDetailsModal(list)}>{list.listName}</td>
                            <td onClick={() => openDetailsModal(list)}>{list.surgeon}</td>
                            <td onClick={() => openDetailsModal(list)}>{list.team}</td>
                            <td onClick={() => openDetailsModal(list)}>{list.specialty}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            <Button variant="primary" onClick={() => {
                localStorage.removeItem('procedureLists');
                fetchProcedureLists();
            }}>
                Atualizar
            </Button>

            <Button variant="danger" onClick={() => setShowDeleteModal(true)} disabled={selectedListIds.length === 0}>
                Apagar Selecionados
            </Button>

            {/* Modal de Confirmação de Exclusão */}
            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmar Exclusão</Modal.Title>
                </Modal.Header>
                <Modal.Body>Tem certeza de que deseja excluir as listas selecionadas?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                        Não
                    </Button>
                    <Button variant="danger" onClick={deleteSelectedLists}>
                        Sim
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal de Detalhes da Lista */}
            <Modal show={!!selectedList} onHide={() => setSelectedList(null)} centered dialogClassName="modal-content">
                <Modal.Header closeButton>
                    <Modal.Title>{selectedList?.listName}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5>Tabela: {formatTableName(selectedList?.tabela)}</h5>
                    <h5>Detalhes dos Cálculos</h5>
                    {renderDetalhesCalculos()}
                    <Button variant="link" onClick={copyToClipboard} className="mt-2">
                        Copiar Cálculos
                    </Button>
                    <p><strong>Total:</strong> R$ {honorarios.total.toFixed(2)}</p>
                    <hr />
                    <Form.Group controlId="acomodacao">
                        <Form.Label>Acomodação</Form.Label>
                        <Form.Control as="select" value={acomodacao} onChange={(e) => setAcomodacao(e.target.value)}>
                            <option>Enfermaria</option>
                            <option>Quarto/Apartamento</option>
                        </Form.Control>
                    </Form.Group>
                    <Form.Check
                        type="checkbox"
                        label="Anestesista Auxiliar"
                        checked={auxiliar}
                        onChange={(e) => setAuxiliar(e.target.checked)}
                    />
                    <Form.Check
                        type="checkbox"
                        label="Horário de Urgência"
                        checked={urgencia}
                        onChange={(e) => setUrgencia(e.target.checked)}
                    />
                    <hr />
                    <h5>Compartilhar com:</h5>
                    <input
                        type="text"
                        className="form-control mb-2"
                        value={sharedWith}
                        onChange={(e) => setSharedWith(e.target.value)}
                        placeholder="Nome de usuário"
                    />
                    <Button onClick={handleAddSharedUser}>Adicionar</Button>
                    <ul>
                        {selectedList?.sharedWith?.map((user, index) => (
                            <li key={index}>{user}</li>
                        ))}
                    </ul>
                    <Button variant="danger" onClick={deleteSelectedList} className="mr-2">
                        Excluir Lista
                    </Button>
                    <Button variant="secondary" onClick={() => setSelectedList(null)}>
                        Fechar
                    </Button>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default HonorariosArquivo;
