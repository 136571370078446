// Auth Dispatch States
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGIN_ERROR = 'LOGIN_ERROR'; // Erro genérico de login
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAIL = 'LOGOUT_FAIL';
export const AUTHENTICATED_SUCCESS = 'AUTHENTICATED_SUCCESS';
export const AUTHENTICATED_FAIL = 'AUTHENTICATED_FAIL';
export const AUTHENTICATION_PENDING = 'AUTHENTICATION_PENDING'; // Autorização pendente
export const AUTHENTICATED_GUEST = 'AUTHENTICATED_GUEST';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL';

// Profile Dispatch States
export const LOAD_USER_PROFILE_SUCCESS = 'LOAD_USER_PROFILE_SUCCESS';
export const LOAD_USER_PROFILE_FAIL = 'LOAD_USER_PROFILE_FAIL';
export const UPDATE_USER_PROFILE_SUCCESS = 'UPDATE_USER_PROFILE_SUCCESS';
export const UPDATE_USER_PROFILE_FAIL = 'UPDATE_USER_PROFILE_FAIL';

// Menu Dispatch States
export const GET_MENU_SUCCESS = 'GET_MENU_SUCCESS';
export const GET_MENU_FAIL = 'GET_MENU_FAIL';
export const RESET_MENU = 'RESET_MENU';
export const GET_MENU_GUEST = 'GET_MENU_GUEST'; // <-- Adicionando o tipo de ação GET_MENU_GUEST


// Error Types (Específicos e Genéricos)
export const USERNAME_EXISTS_ERROR = 'USERNAME_EXISTS_ERROR'; // "Nome de usuário já existe."
export const EMAIL_EXISTS_ERROR = 'EMAIL_EXISTS_ERROR'; // "O email já está registrado."
export const CPF_EXISTS_ERROR = 'CPF_EXISTS_ERROR'; // "O CPF já está registrado."
export const LOGIN_INVALID_ERROR = 'LOGIN_INVALID_ERROR'; // "Dados de login inválidos."
export const USER_NOT_FOUND_ERROR = 'USER_NOT_FOUND_ERROR'; // "Usuário não encontrado."
export const PASSWORD_INCORRECT_ERROR = 'PASSWORD_INCORRECT_ERROR'; // "Senha incorreta."
export const ACCOUNT_PENDING_ERROR = 'ACCOUNT_PENDING_ERROR'; // "Conta pendente de aprovação."
export const AUTHENTICATION_FAIL_ERROR = 'AUTHENTICATION_FAIL_ERROR'; // "Erro ao autenticar."
export const CREATE_USER_FAIL_ERROR = 'CREATE_USER_FAIL_ERROR'; // "Erro ao criar o usuário."
export const GENERIC_ERROR = 'GENERIC_ERROR'; // Erro genérico para fallback
