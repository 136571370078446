import React, { useState } from 'react';
import { Navigate, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { login } from '../actions/auth'; // Importação correta

import 'bootstrap/dist/css/bootstrap.min.css'; 
import '../assets/css/login.css'; 

const Login = ({ login, error, isAuthenticated }) => {
    const [formData, setFormData] = useState({ Username: '', Password: '' });
    const [showModal, setShowModal] = useState(false);

    const { Username, Password } = formData;

    const onChange = (e) => 
        setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await login(Username, Password);
            if (response?.auth_pending) {
                setShowModal(true); 
            }
        } catch (err) {
            console.error('Erro ao processar o login:', err);
        }
    };

    // Redireciona para o dashboard se autenticado
    if (isAuthenticated) {
        return <Navigate to="/dashboard" replace />;
    }

    return (
        <div className="login-container">
            <h1>Entrar</h1>
            <p>Entre na sua conta do sistema</p>

            {error && <p className="error">{error}</p>}

            <form onSubmit={onSubmit}>

                <div className="form-group">
                    <label className="form-label">Usuário:</label>
                    <input
                        type="text"
                        className="form-control"
                        name="Username"
                        placeholder="Usuário*"
                        value={Username}
                        onChange={onChange}
                        required
                    />
                </div>
                <div className="form-group mt-3">
                    <label className="form-label">Senha:</label>
                    <input
                        type="password"
                        className="form-control"
                        name="Password"
                        placeholder="Senha*"
                        value={Password}
                        onChange={onChange}
                        minLength="6"
                        required
                    />
                </div>
                <button className="btn btn-primary mt-3" type="submit">
                    Entrar
                </button>
            </form>

            <p className="mt-3">
                Não tem uma conta? <Link to="/register">Cadastre-se</Link>
            </p>

            {showModal && (
                <div
                    className="modal fade show d-block"
                    tabIndex="-1"
                    role="dialog"
                    style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)', zIndex: 1050 }}
                >
                    <div className="modal-dialog" role="document" style={{ maxWidth: '300px', margin: '100px auto', zIndex: 1055 }}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Autorização Pendente</h5>
                                <button type="button" className="close" onClick={() => setShowModal(false)}>
                                    <span>&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>Sua conta está com autorização pendente. Aguarde o administrador para autorizar o acesso.</p>
                            </div>
                            <div className="modal-footer">
                                <button className="btn btn-primary" onClick={() => setShowModal(false)}>OK</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    error: state.auth.error,
    isAuthenticated: state.auth.isAuthenticated, // Obtém o estado de autenticação do Redux
});

export default connect(mapStateToProps, { login })(Login);
