// src/App.js
import React, { useEffect, useState } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { checkAuthenticated, logout } from './actions/auth';
import Layout from './hocs/Layout';
import Admin from './containers/Admin';
import Home from './containers/Home';
import Register from './containers/Register';
import Login from './containers/Login';
import Dashboard from './containers/Dashboard';
import HonorariosContainer from './containers/HonorariosContainer';
import Account from './containers/Account';
import ResetPassword from './containers/ResetPassword';
import PublicRoute from './hocs/PublicRoute';
import PrivateRoute from './hocs/PrivateRoute';
import TemplateEditor from './containers/TemplateEditor';
import axios from 'axios';
import { Modal } from 'react-bootstrap';

const App = ({ isAuthenticated, checkAuthenticated, logout }) => {
    const [loading, setLoading] = useState(true);
    const [showSessionExpiredModal, setShowSessionExpiredModal] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    // Verifica autenticação apenas uma vez na montagem
    useEffect(() => {
        const initializeAuth = async () => {
            await checkAuthenticated();
            setLoading(false);  // Libera a aplicação após verificação
        };
        initializeAuth();
    }, [checkAuthenticated]);

    // Redireciona para o dashboard se autenticado e estiver na página de login
    useEffect(() => {
        if (isAuthenticated && location.pathname === '/login') {
            navigate('/dashboard', { replace: true });
        }
    }, [isAuthenticated, location, navigate]);

    // Configuração para interceptar respostas do Axios
    useEffect(() => {
        const responseInterceptor = axios.interceptors.response.use(
            response => response,
            error => {
                if (error.response && error.response.status === 401 && error.response.data === 'Token expired') {
                    // Token expirado, mostra modal de sessão expirada
                    setShowSessionExpiredModal(true);
                    setTimeout(() => {
                        logout();
                        localStorage.clear();
                        navigate('/login', { replace: true });
                    }, 3000);
                }
                return Promise.reject(error);
            }
        );

        return () => {
            axios.interceptors.response.eject(responseInterceptor);
        };
    }, [logout, navigate]);

    if (loading) return <p>Carregando...</p>;

    return (
        <Layout>
            <Routes>
                {/* Rotas públicas */}
                <Route element={<PublicRoute />}>
                    <Route path="/login" element={<Login />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/reset_password" element={<ResetPassword />} />
                </Route>

                {/* Rotas privadas */}
                <Route element={<PrivateRoute />}>
                    <Route path="/admin" element={<Admin />} />
                    <Route path="/template_editor" element={<TemplateEditor />} />
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/honorario_anestesio" element={<HonorariosContainer />} />
                    <Route path="/minha_conta" element={<Account />} />
                </Route>

                {/* Rota pública */}
                <Route path="/" element={<Home />} />
            </Routes>

            {/* Modal de sessão expirada */}
            <Modal show={showSessionExpiredModal} onHide={() => setShowSessionExpiredModal(false)} centered>
                <Modal.Header>
                    <Modal.Title>Sessão Expirada</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Sua sessão expirou. Você será redirecionado para a página de login em 3... 2... 1...
                </Modal.Body>
            </Modal>
        </Layout>
    );
};

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { checkAuthenticated, logout })(App);
