import axios from 'axios';
import { GET_MENU_SUCCESS, GET_MENU_FAIL, RESET_MENU } from './types';

let menuLoaded = false; // Flag para evitar múltiplas requisições

// Função para resetar o estado do menu
export const reset_menu = () => {
    menuLoaded = false; // Reseta a flag para permitir novas requisições
    return { type: RESET_MENU };
};

// Função para buscar o menu via API
export const get_menu = (forceUpdate = false) => async (dispatch) => {
    if (menuLoaded && !forceUpdate) return; // Evita múltiplas requisições

    const token = localStorage.getItem('token'); // Obtém o token armazenado

    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                ...(token && { 'Authorization': `Bearer ${token}` }), // Adiciona o token se disponível
            },
        };

        const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/get_menu_options`,
            {}, // Corpo vazio
            config
        );

        const { menu } = response.data;

        if (menu) {
            dispatch({ type: GET_MENU_SUCCESS, payload: menu });
            menuLoaded = true; // Marca como carregado
        } else {
            dispatch({ type: GET_MENU_FAIL });
        }
    } catch (error) {
        console.error('Erro ao buscar o menu:', error);
        dispatch({ type: GET_MENU_FAIL });
    }
};

// Função para forçar a atualização do menu após login/logout
export const refresh_menu = () => (dispatch) => {
    menuLoaded = false; // Reseta a flag para permitir nova requisição
    dispatch(get_menu(true)); // Força a atualização do menu
};
