// src/hocs/Layout.js
import React, { useEffect, useState } from 'react';
import Sidebar from '../components/Sidebar';
import { connect } from 'react-redux';
import { checkAuthenticated } from '../actions/auth';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/layout.css';

const Layout = ({ children, checkAuthenticated }) => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    useEffect(() => {
        const fetchCSRFToken = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/csrf_cookie`);
                axios.defaults.headers.common['X-CSRFToken'] = response.data.csrfToken;
            } catch (error) {
                console.error('Erro ao buscar o CSRF Token:', error);
            }
        };

        const initializeAuth = async () => {
            await fetchCSRFToken();
            await checkAuthenticated();
        };

        initializeAuth();
    }, [checkAuthenticated]);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    return (
        <div className="container-fluid layout-container">
            <button className="menu-toggle d-md-none" onClick={toggleSidebar}>
                ☰
            </button>
            <div className="row">
                <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
                <div className="content-area">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default connect(null, { checkAuthenticated })(Layout);
