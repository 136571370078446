import {
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGIN_ERROR,
    LOGOUT_SUCCESS,
    LOGOUT_FAIL,
    AUTHENTICATED_SUCCESS,
    AUTHENTICATED_GUEST,
    AUTHENTICATED_FAIL,
    DELETE_USER_SUCCESS,
    DELETE_USER_FAIL,
    AUTHENTICATION_PENDING
} from '../actions/types';

const initialState = {
    isAuthenticated: false,  // Inicialmente falso, indicando que o usuário não está autenticado
    authPending: false,  // Indica se uma autenticação está em processo
    error: null,  // Armazena erros de autenticação ou registro
    userRole: 'guest',  // Define o papel padrão do usuário como 'guest'
    successMessage: null  // Armazena mensagens de sucesso (registro, exclusão)
};

export default function authReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case AUTHENTICATED_SUCCESS:
            console.log('Autenticado com sucesso:', payload);
            return {
                ...state,
                isAuthenticated: true,
                userRole: payload?.userRole || 'guest', // Define userRole recebido ou mantém 'guest'
                authPending: false,
                error: null,
                successMessage: null
            };

        case AUTHENTICATED_GUEST:
            console.log('Usuário autenticado como guest.');
            return {
                ...state,
                isAuthenticated: false,
                userRole: 'guest',
                authPending: false,
                error: null,  // Nenhum erro porque é um guest
            };

        case AUTHENTICATED_FAIL:
            console.log('Usuário tratado como guest.');
            return {
                ...state,
                isAuthenticated: false,
                userRole: 'guest',
                authPending: false,
                error: payload?.isGuest ? null : 'Falha na autenticação.',  // Erro apenas se não for guest
            };

        case REGISTER_SUCCESS:
            console.log('Registro bem-sucedido:', payload);
            return {
                ...state,
                successMessage: payload,  // Define mensagem de sucesso do registro
                error: null,
                isAuthenticated: false  // Usuário ainda não autenticado
            };

        case REGISTER_FAIL:
            console.error('Falha no registro:', payload);
            return {
                ...state,
                error: payload,  // Armazena a mensagem de erro
                successMessage: null,
                isAuthenticated: false
            };

        case LOGIN_SUCCESS:
            console.log('Login bem-sucedido:', payload);
            return {
                ...state,
                isAuthenticated: true,
                userRole: payload?.userRole || 'guest',  // Define userRole após login
                authPending: false,
                error: null,
                successMessage: null
            };

        case LOGIN_FAIL:
        case LOGIN_ERROR:
            console.error('Erro no login:', payload);
            return {
                ...state,
                isAuthenticated: false,
                userRole: 'guest',  // Reseta para 'guest' em caso de falha
                error: payload,
                successMessage: null
            };

        case AUTHENTICATION_PENDING:
            console.log('Autenticação pendente.');
            return {
                ...state,
                authPending: true,
                error: null,
                successMessage: null,
            };

        case LOGOUT_SUCCESS:
        case DELETE_USER_SUCCESS:
            console.log('Logout/Exclusão de usuário bem-sucedida.');
            return {
                ...state,
                isAuthenticated: false,
                userRole: 'guest',  // Reseta userRole após logout ou exclusão
                authPending: false,
                error: null,
                successMessage: 'Operação realizada com sucesso.'
            };

        case LOGOUT_FAIL:
        case DELETE_USER_FAIL:
            console.error('Erro na operação:', payload);
            return {
                ...state,
                error: payload,
                successMessage: null
            };

        default:
            return state;  // Retorna o estado atual se a ação não corresponder a nenhum caso
    }
}
