import { GET_MENU_SUCCESS, GET_MENU_FAIL, RESET_MENU, GET_MENU_GUEST } from '../actions/types';

const initialState = {
  menu: [],
};

const NO_AUTH_MENU_OPTIONS = [
  { label: "Cadastro", url: "/register", action: "open" },
  { label: "Lembrar Senha", url: "/reset_password", action: "open" },
  { label: "Login", url: "/login", action: "open" },
];

const menuReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_MENU_SUCCESS:
      return {
        ...state,
        menu: payload,
      };
    case GET_MENU_FAIL:
      return {
        ...state,
        menu: [],
      };
    case RESET_MENU:
      return initialState;
    case GET_MENU_GUEST:
      return {
        ...state,
        menu: NO_AUTH_MENU_OPTIONS,
      };
    default:
      return state;
  }
};

export default menuReducer;
