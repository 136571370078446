import React, { useState } from 'react';
import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist';
import Draggable from 'react-draggable';
import { saveTemplate } from '../services/api';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import '../assets/css/TemplateEditor.css';

// Configuração do PDF.js Worker
GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.16.105/pdf.worker.min.js`;

const TemplateEditor = () => {
    const [pdfPages, setPdfPages] = useState([]);
    const [textBoxes, setTextBoxes] = useState([]);
    const [selectedBox, setSelectedBox] = useState(null);
    const [templateName, setTemplateName] = useState('');
    const [zoom, setZoom] = useState(1);
    const [showModal, setShowModal] = useState(false);
    const [currentBox, setCurrentBox] = useState(null);

    const renderPdfAsImage = async (fileURL) => {
        try {
            const loadingTask = getDocument(fileURL);
            const pdf = await loadingTask.promise;
            const pages = [];
            for (let i = 1; i <= pdf.numPages; i++) {
                const page = await pdf.getPage(i);
                const canvas = document.createElement('canvas');
                const context = canvas.getContext('2d');
                const viewport = page.getViewport({ scale: 1.5 });

                canvas.height = viewport.height;
                canvas.width = viewport.width;

                await page.render({ canvasContext: context, viewport }).promise;
                pages.push(canvas.toDataURL('image/png'));
            }
            setPdfPages(pages);
        } catch (error) {
            console.error('Erro ao renderizar PDF:', error);
        }
    };

    const handlePdfUpload = async (event) => {
        const file = event.target.files[0];
        if (file && file.type === 'application/pdf') {
            const fileURL = URL.createObjectURL(file);
            await renderPdfAsImage(fileURL);
        }
    };

    const addTextBox = () => {
        const newBox = {
            id: `Caixa${textBoxes.length + 1}`,
            x: 50,
            y: 50,
            fontSize: 12,
            letterSpacing: 1,
            content: 'Texto...',
            name: `Caixa${textBoxes.length + 1}`,
        };
        setTextBoxes((prev) => [...prev, newBox]);
    };

    const handleDrag = (e, data, id) => {
        updateTextBox(id, 'x', data.x / zoom);
        updateTextBox(id, 'y', data.y / zoom);
    };

    const updateTextBox = (id, key, value) => {
        setTextBoxes((prev) =>
            prev.map((box) => (box.id === id ? { ...box, [key]: value } : box))
        );
    };

    const deleteTextBox = (id) => {
        setTextBoxes((prev) => prev.filter((box) => box.id !== id));
        if (selectedBox === id) setSelectedBox(null);
    };

    const openModal = (box) => {
        setCurrentBox(box);
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const handleSaveChanges = () => {
        if (currentBox) {
            updateTextBox(currentBox.id, 'fontSize', currentBox.fontSize);
            updateTextBox(currentBox.id, 'letterSpacing', currentBox.letterSpacing);
        }
        closeModal();
    };

    const handleInputChange = (key, value) => {
        setCurrentBox((prev) => ({ ...prev, [key]: value }));
    };

    const selectBox = (box) => {
        setSelectedBox(box.id);
        openModal(box);
    };

    const zoomIn = () => setZoom((prev) => Math.min(prev + 0.1, 2));
    const zoomOut = () => setZoom((prev) => Math.max(prev - 0.1, 0.5));

    const handleSave = async () => {
        if (!templateName) {
            alert('O nome do template é obrigatório.');
            return;
        }

        const formData = new FormData();
        formData.append('templateName', templateName);
        formData.append('textBoxes', JSON.stringify(textBoxes));

        try {
            await saveTemplate(formData);
            alert('Template salvo com sucesso!');
        } catch (error) {
            console.error('Erro ao salvar template:', error);
        }
    };

    return (
        <div className="template-editor">
            <div className="top-bar">
                <input
                    type="text"
                    placeholder="Nome do template"
                    value={templateName}
                    onChange={(e) => setTemplateName(e.target.value)}
                />
                <input type="file" accept="application/pdf" onChange={handlePdfUpload} />
                <button onClick={addTextBox}>Adicionar Caixa de Texto</button>
            </div>

            <div className="layout-container">
                <div className="editor-container">
                    <div className="pdf-page-wrapper">
                        {pdfPages.map((page, index) => (
                            <div className="pdf-page" key={index} style={{ transform: `scale(${zoom})` }}>
                                <img src={page} alt={`Página ${index + 1}`} />
                                {textBoxes.map((box) => (
                                    <Draggable
                                        key={box.id}
                                        defaultPosition={{ x: box.x * zoom, y: box.y * zoom }}
                                        onStop={(e, data) => handleDrag(e, data, box.id)}
                                    >
                                        <div
                                            className={`text-box ${
                                                selectedBox === box.id ? 'selected' : ''
                                            }`}
                                            onDoubleClick={() => selectBox(box)}
                                        >
                                            <textarea
                                                value={box.content}
                                                onChange={(e) =>
                                                    updateTextBox(box.id, 'content', e.target.value)
                                                }
                                            />
                                        </div>
                                    </Draggable>
                                ))}
                            </div>
                        ))}
                    </div>
                </div>

                <div className="text-box-list">
                    <h3>Caixas de Texto</h3>
                    {textBoxes.map((box) => (
                        <div
                            key={box.id}
                            className={`text-box-item ${
                                selectedBox === box.id ? 'highlight' : ''
                            }`}
                        >
                            <input
                                type="text"
                                value={box.name}
                                onChange={(e) => updateTextBox(box.id, 'name', e.target.value)}
                            />
                            <button onClick={() => selectBox(box)}>i</button>
                            <button onClick={() => deleteTextBox(box.id)}>X</button>
                        </div>
                    ))}
                </div>
            </div>

            <div className="zoom-controls">
                <button onClick={zoomOut}>Zoom Out</button>
                <button onClick={zoomIn}>Zoom In</button>
            </div>

            <button className="save-button" onClick={handleSave}>
                Salvar Template
            </button>

            <Modal show={showModal} onHide={closeModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Editar Caixa de Texto</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <input
                        type="number"
                        value={currentBox?.fontSize || ''}
                        onChange={(e) => handleInputChange('fontSize', parseInt(e.target.value))}
                        placeholder="Tamanho da Fonte"
                    />
                    <input
                        type="number"
                        value={currentBox?.letterSpacing || ''}
                        onChange={(e) => handleInputChange('letterSpacing', parseFloat(e.target.value))}
                        placeholder="Espaçamento entre Letras"
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>
                        Cancelar
                    </Button>
                    <Button variant="primary" onClick={handleSaveChanges}>
                        Salvar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default TemplateEditor;