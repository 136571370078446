import axios from 'axios';
import { get_menu, reset_menu } from './menu';
import {
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGIN_ERROR,
    LOGOUT_SUCCESS,
    LOGOUT_FAIL,
    AUTHENTICATED_SUCCESS,
    AUTHENTICATED_GUEST,
    AUTHENTICATED_FAIL,
    DELETE_USER_SUCCESS,
    DELETE_USER_FAIL,
    AUTHENTICATION_PENDING,
    GET_MENU_GUEST,
} from './types';

export const isAuthenticated = () => {
    const token = localStorage.getItem('token');
    if (!token) return false;

    try {
        const { exp } = JSON.parse(atob(token.split('.')[1]));
        if (Date.now() >= exp * 1000) {
            localStorage.removeItem('token');
            localStorage.removeItem('userRole');
            return false;
        }
        return true;
    } catch (e) {
        console.error('Erro ao verificar o token JWT:', e);
        return false;
    }
};

// Função para verificar autenticação e definir o menu

export const checkAuthenticated = () => async (dispatch) => {
    const token = localStorage.getItem('token');

    if (!token) {
        dispatch({ type: AUTHENTICATED_GUEST });
        dispatch({ type: GET_MENU_GUEST }); // Exibir menu para guest
        return;
    }

    try {
        const { exp } = JSON.parse(atob(token.split('.')[1]));

        if (Date.now() >= exp * 1000) {
            localStorage.removeItem('token');
            localStorage.removeItem('userRole');
            dispatch({ type: AUTHENTICATED_FAIL, payload: 'Token expirado.' });
            dispatch({ type: GET_MENU_GUEST });
        } else {
            const userRole = localStorage.getItem('userRole') || 'guest';
            dispatch({ type: AUTHENTICATED_SUCCESS, payload: { userRole } });

            if (userRole === 'guest') {
                dispatch({ type: GET_MENU_GUEST });
            } else {
                dispatch(get_menu(userRole));
            }
        }
    } catch (error) {
        console.error('Erro ao verificar o token:', error);
        localStorage.removeItem('token');
        localStorage.removeItem('userRole');
        dispatch({ type: AUTHENTICATED_FAIL, payload: 'Erro ao verificar o token.' });
        dispatch({ type: GET_MENU_GUEST });
    }
};

// Função de login
export const login = (Username, Password) => async (dispatch) => {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    };

    const body = JSON.stringify({ Username, Password });

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/accounts/login`, body, config);

        if (res.data.token) {
            localStorage.setItem('token', res.data.token);
            localStorage.setItem('userRole', res.data.userRole);

            dispatch({ type: LOGIN_SUCCESS });
            dispatch(get_menu(res.data.userRole));
            return res.data;
        } else if (res.data.error === 'Autorização pendente') {
            dispatch({ type: AUTHENTICATION_PENDING });
            return res.data;
        } else {
            dispatch({ type: LOGIN_FAIL, payload: res.data.error || 'Erro ao autenticar.' });
            return res.data;
        }
    } catch (err) {
        dispatch({ type: LOGIN_ERROR, payload: err.response?.data?.error || 'Erro ao processar o login.' });
        return { error: 'Erro ao processar o login.' };
    }
};

// Função de logout
export const logout = () => async (dispatch) => {
    try {
        localStorage.clear(); // Limpa o armazenamento local
        dispatch(reset_menu()); // Reseta o menu
        dispatch({ type: LOGOUT_SUCCESS });
        window.location.href = "/login"; // Redireciona para login
    } catch (error) {
        dispatch({ type: LOGOUT_FAIL, payload: 'Erro ao processar o logout.' });
    }
};



// Função de registro
export const register = (
    Username, PrimeiroNome, Sobrenome, Email, CPF, DataNascimento, genero, password, re_password
) => async (dispatch) => {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
                },
    };

    const body = JSON.stringify({
        Username, PrimeiroNome, Sobrenome, Email, CPF, DataNascimento, genero, password, re_password,
    });

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/accounts/signup`, body, config);

        if (res.data.success) {
            dispatch({ type: REGISTER_SUCCESS, payload: res.data.message });
            dispatch(get_menu('guest')); // Define o menu como visitante após registro
            return res.data;
        } else {
            dispatch({ type: REGISTER_FAIL, payload: res.data.message || 'Erro ao registrar.' });
        }
    } catch (err) {
        dispatch({ type: REGISTER_FAIL, payload: err.response?.data?.message || 'Erro ao registrar.' });
    }
};

// Função para deletar a conta do usuário
export const delete_account = () => async (dispatch) => {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`, // Inclua o token diretamente
        },
    };

    try {
        const res = await axios.delete(`${process.env.REACT_APP_API_URL}/api/accounts/delete`, config);

        if (res.data.success) {
            dispatch({ type: DELETE_USER_SUCCESS });
            dispatch(reset_menu()); // Reseta o menu após deletar a conta
            dispatch(get_menu('guest')); // Define o menu como visitante
        } else {
            dispatch({ type: DELETE_USER_FAIL, payload: res.data.error || 'Erro ao deletar a conta.' });
        }
    } catch (err) {
        dispatch({ type: DELETE_USER_FAIL, payload: err.response?.data?.error || 'Erro ao deletar a conta.' });
    }
};

