import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { Modal, Button, Table, FormControl } from 'react-bootstrap';
import '../assets/css/admin.css';

const Admin = () => {
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
    const [history, setHistory] = useState([]);

    const [formData, setFormData] = useState({
        email: '',
        primeiroNome: '',
        sobrenome: '',
        cpf: '',
        tipoUsuario: ''
    });

    const apiUrl = `${process.env.REACT_APP_API_URL}/api/accounts/list`;

    const fetchUsers = useCallback(async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(apiUrl, {
                headers: { Authorization: `Bearer ${token}` }
            });
            const data = response.data.users || []; // Garante que data seja uma lista
            setUsers(data);
            setFilteredUsers(data);
        } catch (error) {
            console.error('Erro ao buscar usuários:', error);
            setUsers([]);
            setFilteredUsers([]); // Garante que filteredUsers seja uma lista
        }
    }, [apiUrl]);

    useEffect(() => {
        fetchUsers();
    }, [fetchUsers]);

    useEffect(() => {
        const lowerCaseSearch = searchTerm.toLowerCase();
        setFilteredUsers(
            users.filter(user =>
                Object.values(user).some(value =>
                    value?.toString().toLowerCase().includes(lowerCaseSearch)
                )
            )
        );
    }, [searchTerm, users]);

    const openModal = async (user) => {
        setSelectedUser(user);
        setFormData({
            email: user.email || '',
            primeiroNome: user.primeiroNome || '',
            sobrenome: user.sobrenome || '',
            cpf: user.cpf || '',
            tipoUsuario: user.tipoUsuario || ''
        });

        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/accounts/history/${user.userName}`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setHistory(Array.isArray(response.data) ? response.data : []);
        } catch (error) {
            console.error('Erro ao buscar histórico do usuário:', error);
            setHistory([]); // Garante que history seja uma lista
        }

        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
        setSelectedUser(null);
        setHistory([]);
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSave = async () => {
        try {
            const token = localStorage.getItem('token');

            const updatedFields = {};
            Object.keys(formData).forEach(key => {
                if (formData[key] !== selectedUser[key]) {
                    updatedFields[key] = formData[key];
                }
            });

            if (Object.keys(updatedFields).length > 0) {
                await axios.put(`${process.env.REACT_APP_API_URL}/api/accounts/update`, {
                    username: selectedUser.userName,
                    ...updatedFields
                }, {
                    headers: { Authorization: `Bearer ${token}` }
                });

                fetchUsers(); // Atualiza a lista de usuários após salvar as alterações
            }

            closeModal();
        } catch (error) {
            console.error('Erro ao atualizar usuário:', error);
        }
    };

    const handleSort = (key) => {
        const direction = sortConfig.key === key && sortConfig.direction === 'asc' ? 'desc' : 'asc';
        const sortedUsers = [...filteredUsers].sort((a, b) => {
            if (a[key] < b[key]) return direction === 'asc' ? -1 : 1;
            if (a[key] > b[key]) return direction === 'asc' ? 1 : -1;
            return 0;
        });
        setFilteredUsers(sortedUsers);
        setSortConfig({ key, direction });
    };

    return (
        <div>
            <h2>Administração de Usuários</h2>
            <FormControl
                type="text"
                placeholder="Buscar..."
                className="mb-3"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Button variant="primary" className="btn-update" onClick={fetchUsers}>Atualizar</Button>
            <Table striped bordered hover className="mt-3">
                <thead>
                    <tr>
                        {['Username', 'Email', 'Primeiro Nome', 'Sobrenome', 'CPF', 'Tipo de Usuário'].map((column, index) => (
                            <th key={index} onClick={() => handleSort(column.toLowerCase().replace(' ', ''))}>
                                {column} {sortConfig.key === column.toLowerCase().replace(' ', '') && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                            </th>
                        ))}
                        <th>Ações</th>
                    </tr>
                </thead>
                <tbody>
                    {(filteredUsers && filteredUsers.length > 0) ? (
                        filteredUsers.map((user) => (
                            <tr key={user.userName}>
                                <td>{user.userName}</td>
                                <td>{user.email}</td>
                                <td>{user.primeiroNome}</td>
                                <td>{user.sobrenome}</td>
                                <td>{user.cpf}</td>
                                <td>{user.tipoUsuario}</td>
                                <td>
                                    <Button variant="warning" onClick={() => openModal(user)}>Editar</Button>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="7" className="text-center">Nenhum usuário encontrado</td>
                        </tr>
                    )}
                </tbody>
            </Table>

            {selectedUser && (
                <Modal show={showModal} onHide={closeModal} backdrop="static" keyboard={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Editar Usuário</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form>
                            <div className="mb-3">
                                <label>Email</label>
                                <input type="email" className="form-control" name="email" value={formData.email} onChange={handleChange} />
                            </div>
                            <div className="mb-3">
                                <label>Primeiro Nome</label>
                                <input type="text" className="form-control" name="primeiroNome" value={formData.primeiroNome} onChange={handleChange} />
                            </div>
                            <div className="mb-3">
                                <label>Sobrenome</label>
                                <input type="text" className="form-control" name="sobrenome" value={formData.sobrenome} onChange={handleChange} />
                            </div>
                            <div className="mb-3">
                                <label>CPF</label>
                                <input type="text" className="form-control" name="cpf" value={formData.cpf} onChange={handleChange} />
                            </div>
                            <div className="mb-3">
                                <label>Tipo de Usuário</label>
                                <input type="text" className="form-control" name="tipoUsuario" value={formData.tipoUsuario} onChange={handleChange} />
                            </div>
                        </form>

                        <h5 className="mt-4">Histórico de Modificações</h5>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Data/Hora</th>
                                    <th>Campo</th>
                                    <th>Valor Anterior</th>
                                    <th>Valor Novo</th>
                                    <th>Operação</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(history && history.length > 0) ? (
                                    history.map((record, index) => (
                                        <tr key={index}>
                                            <td>{new Date(record.changed_at).toLocaleString('pt-BR')}</td>
                                            <td>{record.field}</td>
                                            <td>{record.old_value}</td>
                                            <td>{record.new_value}</td>
                                            <td>{record.operation_type}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="5" className="text-center">Nenhum histórico encontrado</td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={closeModal}>Cancelar</Button>
                        <Button variant="primary" onClick={handleSave}>Salvar</Button>
                    </Modal.Footer>
                </Modal>
            )}
        </div>
    );
};

export default Admin;
