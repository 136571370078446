import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { update_profile } from '../actions/profile';
import { delete_account } from '../actions/auth';

const Account = ({
    delete_account,
    update_profile,
    celular_global,
    cidade_global
}) => {
    const [formData, setFormData] = useState({
        celular: '',
        cidade: ''
    });

    const { celular, cidade } = formData;

    useEffect(() => {
        setFormData({
            celular: celular_global,
            cidade: cidade_global
        });
    }, [celular_global, cidade_global]);

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = e => {
        e.preventDefault();
        update_profile(celular, cidade);
    };

    return (
        <div className='container'>
            <h1 className='mt-3'>Minha Conta</h1>
            <p className='mt-3 mb-3'>Atualize seu perfil abaixo:</p>
            <form onSubmit={onSubmit}>
                <div className='form-group'>
                    <label className='form-label mt-3' htmlFor='celular'>Celular</label>
                    <input
                        className='form-control'
                        type='text'
                        name='celular'
                        placeholder={`${celular_global}`}
                        onChange={onChange}
                        value={celular}
                    />
                </div>
                <div className='form-group'>
                    <label className='form-label mt-3' htmlFor='cidade'>Cidade</label>
                    <input
                        className='form-control'
                        type='text'
                        name='cidade'
                        placeholder={`${cidade_global}`}
                        onChange={onChange}
                        value={cidade}
                    />
                </div>
                <button className='btn btn-primary mt-3' type='submit'>Atualizar Perfil</button>
            </form>
            <p className='mt-5'>
                Clique no botão abaixo para deletar sua conta:
            </p>
            <a
                className='btn btn-danger'
                href='#!'
                onClick={delete_account}
            >
                Deletar Conta
            </a>
        </div>
    );
};

const mapStateToProps = state => ({
    celular_global: state.profile.celular,
    cidade_global: state.profile.cidade,
});

export default connect(mapStateToProps, { delete_account, update_profile })(Account);
